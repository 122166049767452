import React, { useEffect } from "react"
import styled from "@emotion/styled"

import NavLink from "./NavLink"

import "./Navbar.scss"
import SectionElement from "./SectionElement"
import Container from "./Container"
import DarkLogo from "../../images/svg/singerly-dark-logo.svg"

const Nav = styled("nav")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 0;

  /* @media screen and (min-width: 1024px) {
    width: calc(100% - 100px);
  } */
`
const Navbar = () => {
  const addSrollEventListener = () => {
    window.addEventListener("scroll", handleScroll)
  }

  const removeScrollEventListener = () => {
    window.removeEventListener("scroll", handleScroll)
  }

  const handleScroll = () => {
    if (window.scrollY > 20) {
      document.querySelector(".header").className = "header scroll"
      document.querySelector(".dark-logo path").style.fill = "#fff"
    } else {
      document.querySelector(".header").className = "header"
      document.querySelector(".dark-logo path").style.fill = "#002343"
    }
  }

  useEffect(() => {
    addSrollEventListener()

    return () => {
      removeScrollEventListener()
    }
  })

  return (
    <SectionElement className="header">
      <Container>
        <Nav>
          <div className="logo-wrapper">
            <DarkLogo className="dark-logo" />
          </div>
          <div className="nav-links">
            <NavLink
              to="https://app.singerly.co/"
              className="round"
              target="_blank"
            >
              Sign up
            </NavLink>
            <NavLink
              to="https://app.singerly.co/"
              className="underline"
              target="_blank"
            >
              Sign in
            </NavLink>
          </div>
        </Nav>
      </Container>
    </SectionElement>
  )
}
export default Navbar
