import styled from "@emotion/styled"

const Container = styled.div`
  max-width: 1145px;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
`

export default Container
