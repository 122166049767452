import React from "react"
import styled from "@emotion/styled"

import NavLink from "./NavLink"
import SectionElement from "./SectionElement"
import Container from "./Container"

import LightLogo from "../../images/svg/singerly-light-logo.svg"

const Nav = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* width: calc(100% - 100px); */
  margin-left: auto;
  margin-right: auto;
  padding: 25px 0;

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > a {
      margin-top: 10px;
      margin-bottom: 10px;
      width: max-content;
    }
  }

  @media screen and (min-width: 768px) {
    .nav-links {
      flex-direction: row;
      margin-bottom: 10px;

      & > a {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;

    .nav-links {
      margin-bottom: 0px;
    }
  }
`

const Footer = () => {
  return (
    <SectionElement id="footer">
      <Container>
        <Nav>
          <LightLogo />
          <div className="nav-links">
            <NavLink to="/blog" className="underline" target="_blank">
              Blog
            </NavLink>
            <NavLink
              to="https://upvote.singerly.co/"
              className="underline"
              target="_blank"
            >
              Feature requests
            </NavLink>
            <NavLink
              to="https://app.singerly.co/"
              className="round"
              target="_blank"
            >
              Sign in
            </NavLink>
          </div>
        </Nav>
      </Container>
    </SectionElement>
  )
}

export default Footer
