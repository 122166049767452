import styled from "@emotion/styled"

import { Link } from "gatsby"

const NavLink = styled(Link)`
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 20px;
  color: #333;
  text-transform: capitalize;
  text-decoration: none;
  position: relative;

  &.round {
    padding: 10px 20px;
    background-color: transparent;
    border-radius: 30px;
    text-decoration: none;
    border: 1px solid #333;
    margin-right: 15px;
    transition: 0.5s;

    &:hover {
      background-color: #333;
      color: #fff;
      box-shadow: 0 8px 16px rgb(51 51 51 / 24%);
    }
  }

  &.underline {
    transition: all 0.2s linear;

    &:after {
      position: absolute;
      bottom: -5px;
      left: 0;
      content: " ";
      width: 0;
      height: 1px;
      width: 1px;
      background-color: transparent;
      transition: all 0.2s linear;
    }

    &:hover {
      &:after {
        width: 100%;
        background-color: #000 !important;
      }
    }
  }
`

export default NavLink
